<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="row">
          <div class="col-md-12">
            <div class="card gutter-b">
              <div class="card-body">
                <div class="row">
                  <validation-provider
                    rules="required|clientNotEmpty"
                    name="Client company"
                    v-slot="{ valid, errors }"
                    class="col-md-3"
                  >
                    <b-form-group id="input-group-3" label="Client Company" label-for="input-3">
                      <b-form-select
                        id="input-3"
                        v-model="job.client"
                        :options="clientOptions"
                        :state="errors[0] ? false : valid ? true : null"
                      ></b-form-select>

                      <b-form-invalid-feedback id="inputLiveFeedback">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <b-form-group
                    id="input-group-1"
                    label="Reference no."
                    label-for="input-1"
                    description="For custom referencing if needed"
                    class="col-md-3"
                  >
                    <b-form-input id="input-1" type="text" v-model="job.reference_no" name="reference_no">
                    </b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <div class="card-title"><h3>Trips</h3></div>

            <div class="card-toolbar" v-if="false">
              <b-button size="lg" variant="white" class="mb-2" @click="addTrip">
                <b-icon icon="plus-circle" aria-label="Add"></b-icon>
              </b-button>
            </div>
          </div>
          <div class="card-body">
            <div>
              <add-trip-form
                v-for="(trip, index) in job.trips"
                :key="trip._id"
                :trip="trip"
                :config="config"
                :index="index"
                @open-address-book="openAddressBook(index)"
              />
            </div>
          </div>
        </div>

        <!-- <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <div class="card-title"><h3>Items</h3></div>

            <div class="card-toolbar">
              <b-button size="lg" variant="white" class="mb-2" @click="addItem">
                <b-icon icon="plus-circle" aria-label="Add"></b-icon>
              </b-button>
            </div>
          </div>
          <div class="card-body">
            <add-item-form
              v-for="(item, index) in job.items"
              :key="item._id"
              :item="item"
              @item-remove="removeItem(index)"
            />
          </div>
        </div> -->
        <submit-button :loading="loading" text="Submit" />
      </b-form>
    </ValidationObserver>

    <b-modal id="bv-modal-address" hide-footer size="xl">
      <template #modal-title> Address book </template>
      <div class="d-block text-center">
        <v-client-table :columns="columns" v-model="addressbooks" :options="options" @row-click="addressBookClicked">
        </v-client-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "@vue/composition-api";
import { formatISO } from "date-fns";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import AddTripForm from "@/components/trip/AddTripForm";
// import AddItemForm from "@/components/item/AddItemForm";
import SubmitButton from "@/components/button/SubmitButton";

import { listClient, createOrder } from "@/api/job.api";

import { useNotify } from "@/utils/notify";
import { useLogisticsAddressBook } from "@/utils/address-book";

import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

class Trip {
  constructor(type, date) {
    this._id = Math.random()
      .toString(16)
      .slice(2);
    this.type = type;
    this.company = "";
    this.date = date;
    this.from_time = "00:00";
    this.to_time = "23:59";
    this.address = "";
    this.unit_no = "";
    this.contact_person = "";
    this.contact_number = "";
    this.remark = "";
  }
}

class Item {
  constructor(name, qty, length, width, height, remark) {
    this._id = Math.random()
      .toString(16)
      .slice(2);
    this.name = name;
    this.qty = qty;
    this.length = length;
    this.width = width;
    this.height = height;
    this.remark = remark;
  }
}

export default {
  name: "createjob",
  components: {
    AddTripForm,
    // AddItemForm,
    ValidationProvider,
    ValidationObserver,
    SubmitButton
  },
  setup(_, context) {
    const { notify } = useNotify(context);
    let todayDate = formatISO(new Date(), { representation: "date" });

    const config = {
      disabletypechange: true,
      disabletimechange: true
    };

    const job = reactive({
      clientId: "",
      referenceNo: "",
      trips: [],
      items: []
    });

    const clientOptions = ref([]);

    const addTrip = function() {
      console.log("add trip");
    };

    const addItem = function() {
      job.items.push(new Item("", 1, 0, 0, 0, ""));
    };

    function removeItem(index) {
      job.items.splice(index, 1);
    }

    const onSubmit = async () => {
      loading.value = true;
      try {
        const response = await createOrder(job);
        notify("Job Created", `A job with the number ${response.data.id} is created`);
        context.root.$router.push({ name: "ListJob" });
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      context.root.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      listClient().then(response => {
        const clients = response.data;

        clients.forEach(client => {
          clientOptions.value.push({ text: client.name, value: client.id });
        });
      });
      job.trips.push(new Trip("PICKUP", todayDate));
      job.trips.push(new Trip("DELIVERY", todayDate));
      // job.items.push(new Item("", 1, 0, 0, 0, ""));

      setTimeout(() => {
        // Remove page loader after some time
        context.root.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    let addressBookIndex;
    const openAddressBook = index => {
      context.root.$bvModal.show("bv-modal-address");
      addressBookIndex = index;
    };

    const addressBookClicked = event => {
      const { company, address, unit_no, contact_person, contact_number, remark } = event.row;

      job.trips[addressBookIndex].company = company;
      job.trips[addressBookIndex].address = address;
      job.trips[addressBookIndex].unit_no = unit_no;
      job.trips[addressBookIndex].contact_person = contact_person;
      job.trips[addressBookIndex].contact_number = contact_number;
      job.trips[addressBookIndex].remark = remark;

      context.root.$bvModal.hide("bv-modal-address");
    };

    const { addressbooks, columns, options } = useLogisticsAddressBook(true);

    const loading = ref(false);

    return {
      job,
      addTrip,
      addItem,
      onSubmit,
      removeItem,
      config,
      clientOptions,

      openAddressBook,
      columns,
      options,
      addressbooks,

      addressBookClicked,
      loading
    };
  }
};
</script>
