import ApiService from "@/core/services/api.service";

const END_POINT = "/client";
const JOB_END_POINT = `${END_POINT}/jobs`;
const ADDRESSBOOK_END_POINT = `${END_POINT}/address-book`;

const listJobWithinDate = params => ApiService.query(`${JOB_END_POINT}`, params);

const createOrder = param => ApiService.post(`${JOB_END_POINT}`, param);

const getJobFullDetails = id => ApiService.get(`${JOB_END_POINT}`, id);

const getUpdateJobDetail = id => ApiService.get(`${JOB_END_POINT}/${id}/update`);

const updateJob = (id, payload) => ApiService.post(`${JOB_END_POINT}/${id}/update`, payload);

const getWaybill = id => ApiService.download(`${JOB_END_POINT}/waybill/${id}`);

///////////////////////////////////////////////////////////////////

const listAddressbook = () => ApiService.query(`${ADDRESSBOOK_END_POINT}`);
const createAddressbook = param => ApiService.post(`${ADDRESSBOOK_END_POINT}`, param);
const updateAddressbook = (id, param) => ApiService.put(`${ADDRESSBOOK_END_POINT}/${id}`, param);
const deleteAddressbook = id => ApiService.delete(`${ADDRESSBOOK_END_POINT}/${id}`);

const getDashboard = () => ApiService.get(`${END_POINT}/dashboard`);

const getAllProduct = () => ApiService.get(`${END_POINT}/warehouse/product`);
const getWarehouseDashboard = () => ApiService.get(`${END_POINT}/warehouse/dashboard`);

export {
  listJobWithinDate,
  createOrder,
  getJobFullDetails,
  getUpdateJobDetail,
  updateJob,
  getWaybill,
  listAddressbook,
  createAddressbook,
  updateAddressbook,
  deleteAddressbook,
  getDashboard,
  getAllProduct,
  getWarehouseDashboard
};
