<template>
  <div class="row align-items-center border-bottom">
    <b-form-group id="input-group-1" label="Type" label-for="input-1" class="col-md-1 col-sm-6">
      <b-form-select
        id="input-1"
        :options="tripType"
        v-model="type"
        :disabled="config.disabletypechange"
      ></b-form-select>
    </b-form-group>

    <div class="col-md-2">
      <div class="row">
        <b-form-group id="input-group-2" label="Company" label-for="input-2" class="col-sm-6 col-md-12">
          <b-form-input id="input-2" type="text" v-model="company"></b-form-input>
        </b-form-group>

        <validation-provider rules="required" name="Date" v-slot="{ valid, errors }" class="col-sm-6 col-md-12">
          <b-form-group id="input-group-3" label="Date" label-for="input-3">
            <b-form-input
              id="input-1"
              type="date"
              :state="errors[0] ? false : valid ? true : null"
              v-model="date"
            ></b-form-input>
            <b-form-invalid-feedback id="inputLiveFeedback">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </div>
    </div>

    <div class="col-md-1">
      <div class="row">
        <b-form-group id="input-group-4" label="From" label-for="input-4" class="col-sm-6 col-md-12">
          <b-form-input
            id="input-4"
            type="time"
            v-model="from_time"
            :disabled="config.disabletimechange"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-5" label="Till" label-for="input-5" class="col-sm-6 col-md-12">
          <b-form-input id="input-5" type="time" v-model="to_time" :disabled="config.disabletimechange"></b-form-input>
        </b-form-group>
      </div>
    </div>

    <b-form-group label="Address" class="col-md-2">
      <b-form-textarea id="input-6" size="sm" v-model="address"></b-form-textarea>
    </b-form-group>

    <b-form-group id="input-group-7" label="Unit no" label-for="input-7" class="col-md-1 col-sm-2">
      <b-form-input id="input-7" type="text" v-model="unit_no"></b-form-input>
    </b-form-group>

    <div class="col-md-1 col-sm-3">
      <div class="row">
        <b-form-group id="input-group-8" label="Contact Person" label-for="input-8" class="col-sm-6 col-md-12">
          <b-form-input id="input-8" type="text" v-model="contact_person"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-9" label="Contact Number" label-for="input-9" class="col-sm-6 col-md-12">
          <b-form-input id="input-9" type="text" v-model="contact_number"></b-form-input>
        </b-form-group>
      </div>
    </div>

    <b-form-group label="Remarks" class="col-md-2">
      <b-form-textarea id="input-10" size="sm" v-model="remark"></b-form-textarea>
    </b-form-group>

    <a class="btn btn-icon btn-light btn-lg mx-3 align-self-center" @click="openAddressbook">
      <span class="svg-icon svg-icon-md svg-icon-primary">
        <!--begin::Svg Icon-->
        <inline-svg src="/media/svg/icons/Communication/Adress-book2.svg"></inline-svg>
        <!--end::Svg Icon-->
      </span>
    </a>
  </div>
</template>

<script>
import { toRefs } from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";

export default {
  name: "AddTripForm",
  components: {
    ValidationProvider
  },
  props: {
    index: Number,
    trip: Object,
    config: Object
  },
  setup(props, { emit }) {
    const trip = toRefs(props.trip);

    const tripType = [
      {
        text: "PICKUP",
        value: "PICKUP"
      },
      {
        text: "DELIVERY",
        value: "DELIVERY"
      }
    ];

    const openAddressbook = () => {
      emit("open-address-book", props.index);
    };

    return {
      tripType,
      type: trip.type,
      company: trip.company,
      date: trip.date,
      from_time: trip.from_time,
      to_time: trip.to_time,
      address: trip.address,
      unit_no: trip.unit_no,
      contact_person: trip.contact_person,
      contact_number: trip.contact_number,
      remark: trip.remark,

      openAddressbook
    };
  }
};
</script>
